import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react'
import { ErrorLabel } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'
import { FullWidthTable } from './FormStep'

// components
import TargetDecimalPlusMinusInput from './DecimalPlusMinusInput'
// end components

// helpers
import { humanReadable } from '../../helpers/decimal'
import { isMyWorkstation, isUnknownWorkstation } from './helpers'
// end helpers

// stores
import { Step } from 'store/Step'
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion'
import { ProductionRequest } from 'store/ProductionRequest'
// end stores


/**
 * Contains the actual table of the material plan to be executed
 */
@observer
export class MaterialPlanTasksTable extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    targetProps: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number.isRequired,
    onPrintProgress: PropTypes.func.isRequired,
    disableTaskPrintButton: PropTypes.bool.isRequired
  }

  static defaultProps = {
    generalErrors: [],
    getErrors: (bomItemId) => [],
    batchSize: 1,
  }

  @observable showDeletionConfirm = null
  @observable taskProgress = {} // save

  constructor(...args) {
    super(...args)

    const { productionRequest, materialPlan } = this.props
    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)
    this.setTaskProgress = this.setTaskProgress.bind(this)

    // this.props.productionRequest has no component batches, so we have this local productionRequest to be able to view those
    this.productionRequestWithBatches = new ProductionRequest(
      { id: parseInt(productionRequest.id) },
      {
        relations: [
          'productionOrder.billOfMaterialVersion.items.articleType.batchTypes',
          'batches.batchUsings.usedBatch.batchType.articleType',
          'batches.batchType.articleType',
        ],
      }
    )
    this.productionRequestWithBatches.setFetchParams({
      where:
        'production_order.bill_of_material_version.items(article_type.batch_types.type:in=make,buy,on_the_fly,stock_count),',
    })

    // eslint-disable-next-line no-unused-vars
    for (const item of materialPlan.items.models) {
      if (item.type === 'task' && item.details) {
        this.taskProgress[item.id] = item.details?.models?.filter((detail) => !detail.value).reduce(
          (total, detail) => total.add(detail.quantityFinished ? detail.quantityFinished : Decimal(0)),
          Decimal(0)
        )
        item.taskProgress = this.taskProgress[item.id]
      } else if (item.type === 'task') {
        this.taskProgress[item.id] = Decimal(0)
      }
    }
  }

  componentDidMount() {
    this.productionRequestWithBatches.fetch()
  }

  @computed get batchRequirements() {
    const { materialPlan, batchSize, productionRequest } = this.props
    const batchRequirements = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.models) {
      if (item.requiredQuantity && Decimal(item.requiredQuantity).eq(0)) {
        // Quantity given by ERP is 0, so we calculate it ourselves
        const requiredQuantity = this.getRequiredQuantityForWholeOrder(item, false)

        batchRequirements[item.id] = Decimal(requiredQuantity).div(Decimal(productionRequest.quantity))
      } else if (item.requiredQuantity) {
        batchRequirements[item.id] = Decimal(item.requiredQuantity).div(Decimal(productionRequest.quantity))
      } else {
        batchRequirements[item.id] = Decimal(item.quantityBatch ?? item.quantity)
      }

      batchRequirements[item.id] = batchRequirements[item.id].mul(Decimal(batchSize))
    }

    return batchRequirements
  }

  getIntermediateQuantity(item) {
    // collect all batches that are "intermediate" SAVED but not yet CONFIRMED
    return item.details.models.reduce(
      (total, itemDetail) =>
        total.add(
          itemDetail.batches.reduce((subTotal, batch) => subTotal.add(this.getQuantityUsed(batch)), Decimal(0))
        ),
      Decimal(0)
    )
  }

  setTaskProgress(item, val) {
    const { onChange } = this.props
    const min = 0
    const max = this.batchRequirements[item.id]

    if(Decimal(val).lte(Decimal(min))){
      this.taskProgress[item.id] = Decimal(min)
      onChange(item, null, null, this.taskProgress[item.id])
    }

    else if(Decimal(val).gte(Decimal(max))){
      this.taskProgress[item.id] = Decimal(max)
      onChange(item, null, null, this.taskProgress[item.id])
    }

    else {
      this.taskProgress[item.id] = Decimal(val)
      onChange(item, null, null, this.taskProgress[item.id])
    }

  }

  renderPerformTask(materialPlanItem, itemChecked) {
    const { materialPlan, onChange, value, step } = this.props
    return (
      <Checkbox
        toggle
        data-test-materials-item={materialPlanItem.id}
        checked={itemChecked}
        onChange={(e, { checked }) => {
          let itemsFinished = null
          // Check bom items finished
          const items = materialPlan.items.filter(
            (item) => item.type === 'task' && isMyWorkstation(step.workStation.code, item)
          )
          // eslint-disable-next-line
          for (const item of items) {
            if (value[item.id] === undefined || value[item.id] === false) {
              itemsFinished = false
              console.log('false for ', item.id)
              break
            }
          }
          if (itemsFinished === null) {
            itemsFinished = true
          }
          onChange(materialPlanItem, checked, itemsFinished, null)
        }}
      />
    )
  }

  getQuantityUsed(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.batchUseds.models.reduce((total, { quantity }) => total.add(quantity), Decimal(0))
    }
    return batch['usage'] ? batch['usage'] : 0
  }

  addItemUnits(item, requiredQuantity, orderLevel = false, addUnitSuffix = true) {
    const { required } = this.props

    let unit = t('form.duration.minutes')
    if (requiredQuantity.eq(Decimal(0))) {
      unit = ''
    } else if (item.unit?.length > 0) {
      unit = item.unit
    }

    if (requiredQuantity.eq(Decimal(0)) && orderLevel && !item.shopOrderRoutingStep.isNew) {
      requiredQuantity = Decimal(item.shopOrderRoutingStep.run * required)
    }

    if (!addUnitSuffix) {
      return requiredQuantity
    }

    return `${humanReadable(requiredQuantity, 1)} ${unit}`
  }

  getRequiredQuantityForWholeOrder(item, addUnitSuffix = true) {
    let requiredQuantity = Decimal(0)
    if (item.requiredQuantity) {
      requiredQuantity = Decimal(item.requiredQuantity ?? 0)
    }

    return this.addItemUnits(item, requiredQuantity, true, addUnitSuffix)
  }

  renderMaterialPlan(materialPlanItem) {
    const { getErrors, onPrintProgress, disableTaskPrintButton } = this.props

    const errors = getErrors(materialPlanItem.id)

    const capMax = this.batchRequirements[materialPlanItem.id]

    return (
      <Table.Row material-plan-item={materialPlanItem.id}>
        <Table.Cell width={1}>{materialPlanItem.number}</Table.Cell>
        <Table.Cell width={3}>{materialPlanItem.description}</Table.Cell>
        <Table.Cell width={5}>
          {/*If there is no article type code to generate a tag, show the name. Otherwise, only the tag*/}
          {materialPlanItem.articleType.code && materialPlanItem.articleType.getLink()}
          {!materialPlanItem.articleType.code && ` ${materialPlanItem.articleType.name}`}
        </Table.Cell>
        <Table.Cell collapsing>
          <Icon size="big" name={materialPlanItem.backflush ? 'check square' : 'square outline'} />
        </Table.Cell>
        <Table.Cell data-test-required-quantity={materialPlanItem.id} colSpan={0}>
          {this.getRequiredQuantityForWholeOrder(materialPlanItem)}
        </Table.Cell>
        <Table.Cell width={5} data-test-done={materialPlanItem.id}>
          {/* if task, we want to be able to partially finish stuff, save and only finish when all is done (T44702) */}
          <TargetDecimalPlusMinusInput
            key={`${materialPlanItem.id}-${capMax}`} // Make sure that react-text-mask re-applies props when changing capMax.
            id={materialPlanItem.id}
            capMin={0}
            capMax={capMax}
            target={materialPlanItem}
            setTarget={this.setTaskProgress}
            value={this.taskProgress[materialPlanItem.id]}

            // additional LimitedDecimalInput props
            placeholder={t('workStation.production.performModal.scan.quantity')}
            suffix={`    / ${humanReadable(this.batchRequirements[materialPlanItem.id],1)}`}
            decimalSymbol="."
            thousandsSeparatorSymbol=","
            decimalLimit={1}
          />
          {errors.filter((err) => err.code === 'task_not_finished').length > 0 && (
            <ErrorLabel>
              {errors
                .filter((err) => err.code === 'task_not_finished')
                .map(({ message }, i) => (
                  <div key={i}>{message}</div>
                ))}
            </ErrorLabel>
          )}
        </Table.Cell>
        <Table.Cell colSpan={0}>
        <Button
                data-test-task-print-button={materialPlanItem.id}
                icon="print"
                disabled={disableTaskPrintButton}
                onClick={() => onPrintProgress(materialPlanItem)}
              />
        </Table.Cell>
      </Table.Row>
    )
  }

  state = { toggled: false }

  handleClick = (e) => {
    this.setState((prevState) => ({
      toggled: !prevState.toggled,
    }))
  }

  render() {
    const { materialPlan, step, generalErrors } = this.props
    const hasUnlinkedItems =
      materialPlan.items.filter((item) => item.type === 'task' && isUnknownWorkstation(item)).length !== 0
    const hasLinkedItems =
      materialPlan.items.filter((item) => item.type === 'task' && isMyWorkstation(step.workStation.code, item))
        .length !== 0
    let table = []

    //Table for Material Plan Tasks
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.number.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.description.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.articleType.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.backflush.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.required.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.finished.label')}</Table.HeaderCell>
        <Table.HeaderCell>Print label</Table.HeaderCell>
      </Table.Row>
    )

    table.push(
      <>
        {
          // if there are any linked items, render the header as well
          hasLinkedItems ? (
            <Table.Header>{tableHeader}</Table.Header>
          ) : (
            //else, display a message saying there are no linked tasks/materials
            <Table.Row>
              <Table.Cell colSpan="8">{t('formStepField.field.materialPlan.noTasks')}</Table.Cell>
            </Table.Row>
          )
        }
        <tbody data-material-plan-table="task">
          {materialPlan.items
            .filter((item) => item.type === 'task' && isMyWorkstation(step.workStation.code, item))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => (
              <div key={i}>{message}</div>
            ))}
          </ErrorLabel>
        )}
      </>
    )

    // Extra accordion in case there are any tasks/materials without any linked workstations.
    // Since we don't have a place for these items, we display them on all workstations in an
    // unobstructive ways
    if (hasUnlinkedItems) {
      table.push(
        <>
          <Table.Row>
            <Table.Cell onClick={this.handleClick} colSpan="3" test-accordion-unlinked-items="task">
              {!this.state.toggled && <Icon name="chevron right" />}
              {this.state.toggled && <Icon name="chevron down" />}
              {t('formStepField.field.materialPlan.noWorkstationLinked')}
            </Table.Cell>
          </Table.Row>
          {!hasLinkedItems && this.state.toggled ? tableHeader : null}
          {this.state.toggled && (
            <tbody data-material-plan-unlinked-items-table="task">
              {materialPlan.items
                .filter((item) => item.type === 'task' && isUnknownWorkstation(item))
                .map(this.renderMaterialPlan)}
            </tbody>
          )}
        </>
      )
    }

    return (
      <FullWidthTable padded basic="very">
        {table}
      </FullWidthTable>
    )
  }
}
